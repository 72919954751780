import { createTheme, CssBaseline, lighten, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

const PRIMARY_COLOR = '#030a8c';

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    setTheme(
      createTheme({
        components: {
          MuiButton: {
            defaultProps: { variant: 'contained' },
            styleOverrides: { root: { textTransform: 'inherit' } },
          },
          MuiTextField: {
            styleOverrides: {
              root: { '& .MuiInputBase-root.Mui-readOnly': { backgroundColor: 'rgba(0,0,0,0.06)' } },
            },
          },
        },
        palette: {
          background: { default: lighten(PRIMARY_COLOR, 0.96), primary: lighten(PRIMARY_COLOR, 0.87) },
          info: { main: PRIMARY_COLOR },
          primary: { main: PRIMARY_COLOR },
          text: { secondary: 'rgba(0,0,0,0.8)' },
        },
        typography: { fontFamily: '"Source Sans Pro", sans-serif' },
      }),
    );
  }, []);

  return theme ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  ) : null;
}
